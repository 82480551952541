import type { FormControlData, FormControlValue, CreateFormControlProps } from "../SHARED/_model";
import type { ICollectionTextArea } from "./_model";
import { createFormControl } from "solid-forms";
import TextArea from "./main";
import { Error } from "../error";
import { Label } from "../label";

export default function createTextArea<V extends FormControlValue, D extends FormControlData = FormControlData>(
  ...args: CreateFormControlProps<V, D>
): ICollectionTextArea<V, D> {
  const control = createFormControl<V, D>(...args);
  // console.log("control id ", control.id);
  const name = String(control.id);
  return {
    control,
    name,
    Label: (props) => <Label control={control} for={name} {...props} />,
    Error: (props) => <Error control={control} {...props} />,
    TextArea: (props) => <TextArea control={control} name={name} {...props} />,
  };
}
